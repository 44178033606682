import {ApolloClient, ApolloLink, ApolloProvider, useQuery, split} from '@apollo/client';
import React from 'react';
import Pages from './pages';
import Login from './pages/login';
import {createCache, authLink, httpLink, initCache, batchHttpLink} from './links';
import {ThemeProvider, StylesProvider} from '@material-ui/core/styles';
import theme from "./ui/theme";
import {gql} from "@apollo/client";
import * as Sentry from '@sentry/react';
import { BrowserTracing } from "@sentry/tracing";
import {loader} from 'graphql.macro';
import jwtDecode from 'jwt-decode';
import ErrorBoundary from './components/ErrorBoundary';
import SnackbarProviderCustom from './components/snackbar/snackbarProviderCustom';
import packageJson from '../package.json';
import {format} from "date-fns";
import {getInitFromDate, getInitToDate} from "./utils";
import {onError} from "@apollo/client/link/error";
import {navigate} from "@gatsbyjs/reach-router";
import {PersistSettings} from "./components/PersistSettings";
import {createRoot} from "react-dom/client";
import {errorCodes} from "./utils/apollo/network/errorCodes";
import {removeAllShipments} from "./utils/apollo/cache/removeAllShipments";

const IS_USER_LOGGED_IN = loader('./gql/query/client/isLoggedIn.graphql');


let cache;
let client;

let classNameIndex = 0;
const generateClassName = (rule, styleSheet) => {
    classNameIndex++;
    return `${ styleSheet.options.classNamePrefix }-${ rule.id  }-${ rule.key }-${ classNameIndex }`;
}

if (process.env.REACT_APP_PRODUCTION === 'TRUE') {
    Sentry.init({
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.1,
        dsn: process.env.REACT_APP_SENTRY,
        release: process.env.REACT_APP_COMMIT_SHA || null,
        beforeSend(event, hint) {
            if (event.exception) {
                let options = {eventId: event.event_id};
                if (cache) {
                    const ABOUT_USER = loader('./gql/query/client/aboutUser.graphql');
                    const data = cache.readQuery({
                        query: ABOUT_USER,
                    });

                    if (data && data.user.kod) {
                        options.user = {
                            name: data.user.kod,
                            email: data.user.kod + '@random.com',
                        }
                    } else {
                        options.user = {
                            name: 'neprihlaseny',
                            email: 'neprihlaseny@random.com',
                        }
                    }
                }
                Sentry.showReportDialog(options);

                // try clear store after crash
                if (client) {
                    (async () => {
                        await client.clearStore();
                    })();
                }
            }
            return event;
        },
    });
    Sentry.setTag("version", packageJson.version);
}

(async () => {
    let data = null;

    try {
        cache = await createCache();
        try {
            const ABOUT_USER = loader('./gql/query/client/aboutUser.graphql');
            data = cache.readQuery({
                query: ABOUT_USER,
            });
            if (process.env.REACT_APP_PRODUCTION === 'TRUE' && data?.user?.kod) {
                Sentry.setUser({...data, id: data.user.kod, 'type': 'rozvoz'});
            }
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }

        client = new ApolloClient({
            cache: cache,
            link: ApolloLink.from([
                onError(({graphQLErrors, networkError, response}) => {
                    if (graphQLErrors)
                        graphQLErrors.forEach((err) => {
                            console.log(
                                `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
                            );
                            switch (err.extensions.code) {
                                case errorCodes.UNAUTHENTICATED:
                                    if (response) {
                                        response.errors = undefined;
                                    }
                                    (async () => {
                                            client.writeQuery({
                                            query: IS_USER_LOGGED_IN,
                                            data: {
                                                isLoggedIn: false,
                                                token: null,
                                            }
                                        });
                                        //await client.resetStore();
                                        //initCache(client);
                                        navigate('/');
                                    })();
                                    break;
                                case errorCodes.NOT_PAID_COD:
                                    removeAllShipments(cache);
                                    break;
                                default:
                            }
                        });

                    if (networkError) console.log(`[Network error]: ${networkError}`);
                }),
                authLink,
                split(
                    operation => operation.getContext().batch === true,
                    batchHttpLink,
                    httpLink
                ),
            ]),
            name: 'Kurier web client',
            version: packageJson.version,
            /*            headers: {
                            authorization: data.token ? `Bearer ${data?.token}` : '',
                            'client-name': 'Web client',
                            'client-version': '1.0.0',
                        },*/
        });
/*        if (data === null) { // new schema version
            console.log('clearStore')
            await client.clearStore();
        }*/
        client.onResetStore(() => initCache(client));
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }

    try {
        const FILTER = loader('./gql/query/client/all.graphql');
        data = client.readQuery({
            query: FILTER,
        });

        // move filter days from cache
        if (typeof data !== "undefined" && data !== null) {
            if (data?.filter?.dateChangeDate !== format(new Date(), 'dd.MM.yyyy')) {
                const filter = {
                    ...data.filter,
                    toDate: getInitToDate(),
                };

                if (!data?.filter?.isFromChanged) {
                    //from date set on today -7
                    filter['fromDate'] = getInitFromDate();
                }

                // set to date on today
                client.writeQuery({
                    query: FILTER,
                    data: {
                        filter
                    },
                });
            }
        } else {
            initCache(client, false);
        }
    } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        //set default state
        initCache(client, true);
    } finally {
        const IS_LOGGED_IN = loader('./gql/query/client/isLoggedIn.graphql');

        function IsLoggedIn() {
            const {data} = useQuery(IS_LOGGED_IN);
            if (data && data.token != null) {
                let decode = jwtDecode(data.token);

                if (decode.exp < format(new Date(), 't')) {
                    cache.writeQuery({
                        query: gql`
                            {
                                isLoggedIn @client
                                token @client
                            }
                        `,
                        data: {
                            isLoggedIn: false,
                            token: null,
                        },
                    });

                    return <Login/>;
                }
                return data.isLoggedIn ? <Pages/> : <Login/>;
            }
            return <Login/>;
        }

        const container = document.getElementById('root');
        const root = createRoot(container);
        root.render(
            <ApolloProvider client={client}>
                <StylesProvider generateClassName={generateClassName}>
                    <ThemeProvider theme={theme}>
                        <SnackbarProviderCustom>
                            <ErrorBoundary>
                                <PersistSettings/>
                                <IsLoggedIn/>
                            </ErrorBoundary>
                        </SnackbarProviderCustom>
                    </ThemeProvider>
                </StylesProvider>
            </ApolloProvider>
        );
    }
})();
